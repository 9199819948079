import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Logo from "../img/logo.svg"
import LogoDark from "../img/logo-dark.svg"
import FB from "../img/icon-facebook.svg"
import Twitter from "../img/icon-twitter.svg"
import IG from "../img/icon-instagram.svg"

import cssda from "../img/award-nominee-cssda-160-r.png"
import gptw from "../img/gptw-badge.svg"

export default ({ location, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  const rootPath = `${__PATH_PREFIX__}/`
  const isHomepage = location.pathname == rootPath
  const isProducts = location.pathname.includes("/products")
  const isServices = location.pathname.includes("/services")
  const isClients = location.pathname.includes("/clients")
  const isJobs = location.pathname.includes("/careers")
  const year = new Date().getFullYear()
  return (
    <div>
      <Helmet>
        <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
        <meta charSet="utf-8" />
        <title>SmashTaps - Digital Product Studio</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no"
        />
        <link
          rel="shortcut icon"
          href="https://smashtaps-web-assets.netlify.com/img/favicon.png"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-touch-fullscreen" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta
          content="We are a product studio dedicated to crafting stunning digital experiences that make your life better."
          name="description"
        />
        <meta content="SmashTaps" name="author" />
        <meta
          name="keywords"
          content="digital product studio, UI design, UI/UX Design, app development, development, engineering, product design, product development, software consulting, website development, mobile apps"
        />
        <meta name="facebook-domain-verification" content="pmlhl4x8wq7y6bgo44zqac0mjf5zmy" /> 
        <link
          href="https://smashtaps-web-assets.netlify.com/css/pace.css"
          rel="stylesheet"
          type="text/css"
        />
        <script src="https://smashtaps-web-assets.netlify.com/js/pace.min.js"></script>
        <meta
          property="og:image"
          content="https://smashtaps-web-assets.netlify.com/img/smashtaps-cover.jpg"
        />
        <meta
          property="og:description"
          content="We are a product studio dedicated to crafting stunning digital experiences that make your life better."
        />
        <meta property="og:url" content="http://smashtaps.co" />
        <meta property="og:title" content="SmashTaps" />
        <script src="https://smashtaps-web-assets.netlify.com/js/scripts_concat.js"></script>
        <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script>
      </Helmet>
      <div id="wrapper">
        <Link
          className={`navbar-brand visible-xs ${
            !isHomepage ? "navbar-brand-black" : ""
          }`}
          to={`/`}
        >
          <img alt="SmashTaps Logo" src={isHomepage ? Logo : LogoDark} width={90} height={83}/>
        </Link>

        <button className="c-hamburger c-hamburger--htx visible-xs">
          <span>Menu</span>
        </button>
        <nav className="navbar navbar-transparent navbar-fixed-top">
          <div className="container">
            <div className="navbar-header hidden-xs">
              <Link
                className={`navbar-brand ${
                  !isHomepage ? "navbar-brand-black" : ""
                }`}
                to={`/`}
              >
                <img alt="SmashTaps Logo" src={LogoDark} width={90} height={83} />
              </Link>
            </div>
            <div id="navbar">
              <ul className="nav navbar-nav navbar-right">
                <li className="visible-xs">
                  <Link to={`/`}>
                    <img alt="SmashTaps Logo" src={Logo} width={125} height={115} />
                  </Link>
                </li>
                <li className={`${isProducts ? "active" : ""}`}>
                  <Link to={`/products`}>Products</Link>
                </li>
                <li className={`${isServices ? "active" : ""}`}>
                  <Link to={`/services`}>Services</Link>
                </li>
                <li className={`${isClients ? "active" : ""}`}>
                  <Link to={`/clients`}>Clients</Link>
                </li>
                <li className={`${isJobs ? "active" : ""}`}>
                  <Link to={`/careers`}>Careers</Link>
                </li>
                <li className="m-l-10 sm-no-margin hidden-xs">
                  <a
                    href="https://calendly.com/smashtaps/schedule-a-meeting"
                    className="btn btn-bordered btn-rounded btn-black"
                    target="_blank"
                    // data-toggle-contact
                  >
                    Schedule a call
                  </a>
                </li>
                <li className="visible-xs">
                  <a
                    href="https://calendly.com/smashtaps/schedule-a-meeting"
                  // data-toggle-contact
                  >
                    Schedule a call
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <a
          id="gptw-badge"
          href="https://www.facebook.com/SmashTaps/posts/3206219362981194" 
          target="_blank" 
          rel="noopener"
        >
          <img src={gptw} width={60}/>
        </a>
        <a
          id="cssda-badge"
          rel="noopener"
          href="http://www.cssdesignawards.com/sites/smashtaps/29402/"
          target="_blank"
        >
          <img width={80} height={96} alt="CSS Design awards Logo"  src={cssda} />
        </a>

        <div
          className={`site-content full-height ${
            isHomepage ? "no-padding" : ""
          }`}
        >
          <div id="primary" className="content-area">
            <main id="main" className="site-main" role="main">
              {children}
            </main>
          </div>

          {/* <hr/> */}

          <div className="bg-master-darkest footer">
            <div className="container">
      
              <div className="row">
                <div className="col-md-6">
                  <h2 className="text-white m-t-30">Get in touch!</h2>

                  <p className="text-white hint-text m-t-20 m-b-0 ">SmashTaps is a Digital Product Studio based in forever sunny Colombo, Sri Lanka 🏝  that designs and
                     develops web and mobile apps for the entire world! We like to partner with startups and scale-ups
                     to create user-centric digital experiences.</p>
                  <a
                    className="btn btn-rounded btn-black btn-lg m-t-20 "
                    href="mailto:hello@smashtaps.co"
                  >
                    hello@smashtaps.co
                  </a>

                  <div className="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="45" data-nofollow="true" data-expandifr="true" data-clutchcompany-id="1496774"></div>

                </div>
                <div className="col-md-3">
                  <h2 className="text-white m-t-30">So sociable</h2>
                  <ul className="list-unstyled m-t-20">
                    <li><a
                      href="https://www.facebook.com/SmashTaps/"
                      rel="noopener"
                      className="text-white hint-text"
                      target="_blank"
                    >Facebook</a></li>
                    <li className="m-t-10"><a
                      href="https://www.instagram.com/smashtapshq/"
                      rel="noopener"
                      className="text-white hint-text"
                      target="_blank"
                    >Instagram</a></li>
                    <li className="m-t-10"><a 
                    href="https://www.linkedin.com/company/smashtaps/"
                    rel="noopener" 
                    className="text-white hint-text"
                    target="_blank">LinkedIn</a></li>
                    <li className="m-t-10"><a
                      href="https://twitter.com/SmashTaps"
                      rel="noopener"
                      className="text-white hint-text"
                      target="_blank"
                    >Twitter</a></li>
                    <li className="m-t-10"><a
                      href="https://www.youtube.com/channel/UCr60UbQS__8hp2DIgmFeCZQ"
                      rel="noopener"
                      className="text-white hint-text"
                      target="_blank"
                    >YouTube</a></li>
                  </ul>
                </div>
                <div className="col-md-3 text-white">
                <h2 className="text-white m-t-30">Our Offices</h2>

                  <address className="m-t-20 hint-text">
                    SmashTaps LLC,
                    2055 Limestone Rd STE 200-C, 
                    Wilmington, Delaware 19808
                  </address>
                  <address className="m-t-20 hint-text">
                    SmashTaps Pvt. Ltd,
                    5 1/1 Chandra de Silva Mawatha,
                    Nugegoda, Sri Lanka
                  </address>
                  
                  <div className="m-t-30">
                    <small className="hint-text ">&copy; {year} SmashTaps Pvt. Ltd</small>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="contact-overlay" style={{ display: "none" }}>
        <a className="contact-close-btn" href="#"></a>
        <div className="container full-height">
          <div className="wrapper full-height">
            <div className="row">
              <div className="col-sm-9">
                <h2 className="text-white">Hey there!</h2>
                <h2 className="text-white">
                  Have anything interesting for us / like to join our amazing team? / just want to say hi?
                </h2>
                <h2 className="text-white no-margin">
                  <a className="email-link" href="mailto:hello@smashtaps.co">
                    hello@smashtaps.co
                  </a>
                </h2>
              </div>
            </div>
            
            <div className="contact-footer">
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
